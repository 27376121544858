import {
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { BIDashboardSettings } from 'components/dashboard/Metrics/metrics.types';
import { LoaderContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';

import { Loader } from 'semantic-ui-react';

import Table from 'components/UI/TableConfig/Table';
import {
  useManageReportData,
  UseManageReportDataParams,
} from 'components/dashboard/Metrics/Widget/Report/ReportTable/hooks/useManageReportData';

import { metricObjectToPlural } from 'components/dashboard/Metrics/Widget/Report/helpers';
import { isAxiosError } from 'axios';
import { onChangeCallback } from 'components/UI/common/TypedTable/TypedTable';
import { toast } from 'react-toastify';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { usePageSize } from 'components/hooks/usePageSize';
import { ACCOUNT } from 'components/dashboard/Metrics/constants';
import { OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { TABLE_ID } from 'common/constants';
import {
  useCommonClickHandler,
  useSortColumns,
  useManageRevbiTableConfigurationColumns,
  OnClick,
} from 'components/hooks/RevbiColumns';
import { useMemo, useState } from 'react';
import { getCSVDownloadUrl } from 'components/dashboard/Metrics/Widget/Report/ReportTable/helpers';
import { getShowOrHideColumns } from 'selectors/table';
import { useSelector } from 'react-redux';

interface Props {
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

const metricObjectToPageSizeKey = (metricObject: string) => {
  switch (metricObject) {
    case OPPORTUNITY:
      return 'Opportunities';
    case ACCOUNT:
      return 'Accounts';
    default:
      return 'RevbiCustomObject';
  }
};

const ReportTable = ({ widget }: Props) => {
  const [userUpdatedField, setUserUpdatedField] = useState<boolean>();

  const metricObject = widget.metric_list?.[0]?.object as string;
  const pageSizeKey = metricObjectToPageSizeKey(metricObject);
  const [pageSize] = usePageSize(pageSizeKey);

  const widgetOrderBy = widget.order_by_column?.name;
  const widgetOrderByDirection = widget.order_by?.[0];
  const widgetSortOrder = widgetOrderBy
    ? `${widgetOrderByDirection === 'ascending' ? '' : '-'}${widgetOrderBy}`
    : '';

  const dashboardFilters =
    widget.dashboard_filters
      ?.filter((filter) => filter.column.name !== 'shared.__changes_since')
      ?.filter((elem) => elem.operator !== 'all') ?? [];

  const metricFilters: BIMetricsQueryFilter[] =
    widget.metric_list?.[0]?.filters ?? [];

  // 0 on .limit means that the widget has no limit
  const widgetHasLimit = !!widget.limit;

  const limit = widgetHasLimit ? Math.min(pageSize, widget.limit!) : pageSize;

  const params: UseManageReportDataParams = {
    order_by_expression: widgetSortOrder,
    limit,
    load_extras: true,
    // Filters
    filters: metricFilters ?? [],
    template_filters: widget.template_filters ?? [],
    dashboard_filters: dashboardFilters,
    total_number_of_records: widget.limit,
  };

  const {
    isLoading,
    isSuccess,
    columns,
    items,
    totalRow,
    itemsCount,
    updateObjectField,
    handlePaginationChange,
    pageNumber,
    handleSort,
    sortOrder,
    invalidateData,
  } = useManageReportData({
    params,
    metricObject,
    tableConfigurationUrl: widget.table_display_id || '',
  });

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const objectId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(objectId, fieldToUpdate);

    try {
      await updateObjectField({
        id: objectId,
        editedFields: updateOperation,
      });

      toast.success('Field updated successfully');
      setSuccessRowField(objectId, fieldToUpdate);
      setUserUpdatedField(true);
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update field');
      setErrorRowField(objectId, fieldToUpdate);
    }
  };

  const title = `Showing ${metricObjectToPlural(metricObject || '')}`;

  const { parseColumns } = useManageRevbiTableConfigurationColumns();

  const { sortColumns } = useSortColumns();

  const { onClick: commonClickHandler } = useCommonClickHandler();

  const onClick: OnClick = (column, row, extraData) => {
    const isHandled = commonClickHandler(column, row);
    if (!isHandled) {
      console.warn(
        `Click on ${column.field} (${column.label}) of type ${column.type} not supported`
      );
    }
  };

  const tableColumns = sortColumns(
    parseColumns(columns, {
      onClick,
    })
  );

  /**
   * Widget limit behavior:
   * - Data is always retrieved with pagination regardless of widget limit
   * - If widget.limit is 0: Shows all available items with pagination (uses totalCount)
   * - If widget.limit > 0: Shows only up to the limit, still paginated
   */

  const itemsCountToShow = widgetHasLimit
    ? Math.min(widget.limit!, itemsCount)
    : itemsCount;

  const itemsOverflowLimit = pageNumber * limit > itemsCountToShow;

  // Slice items array when we hit widget's limit
  // Example: with limit=99 and page_size=50
  // Page 1: Shows 50 items
  // Page 2: Shows remaining 49 items (99 - 50)
  const itemsToShow =
    widgetHasLimit && itemsOverflowLimit
      ? items.slice(0, itemsCountToShow - (pageNumber - 1) * limit)
      : items;

  const downloadButton = useMemo(
    () => getCSVDownloadUrl(metricObject, params, tableColumns),
    [metricObject, params, tableColumns]
  );

  const onRefreshClick = () => {
    setUserUpdatedField(false);
    invalidateData();
  };

  const showOrHideColumns = useSelector(getShowOrHideColumns);

  return (
    <>
      {isLoading && (
        <LoaderContainer>
          <Loader active content="Loading" />
        </LoaderContainer>
      )}
      {isSuccess && !isLoading && (
        <Table
          tableId={`${TABLE_ID.REVBI_REPORT}-${widget.id || widget._id}`}
          hasRefreshButton={userUpdatedField}
          onRefresh={onRefreshClick}
          downloadButton={downloadButton}
          title={title}
          columns={tableColumns}
          showOrHideColumns={showOrHideColumns}
          data={itemsToShow}
          loading={isLoading}
          totalRow={totalRow}
          totalCount={itemsCountToShow || 0}
          currentPage={pageNumber}
          onChange={handleChange}
          statuses={rowStatuses}
          rowsPerPage={limit}
          sortOrder={sortOrder}
          onSort={handleSort}
          onPaginationChange={handlePaginationChange}
          styleFirstColumn
          firstColumnSize={400}
          hideSearch
          pinnableColumns
          hidePaginationEnd
          fullscreen
          showColumnsVisibilityToggle
        />
      )}
    </>
  );
};

export default ReportTable;
