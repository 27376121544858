import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { AdditionalMetrics } from 'components/dashboard/Metrics/Widget/FunnelWidget/AdditionalMetrics/AdditionalMetrics';
import { FunnelChart } from 'components/dashboard/Metrics/Widget/FunnelWidget/Visualization/Chart/FunnelChart';
import { FunnelTable } from 'components/dashboard/Metrics/Widget/FunnelWidget/Visualization/Table/FunnelTable';
import {
  MetricsBlock,
  WidgetTopBar,
} from 'components/dashboard/Metrics/Widget/FunnelWidget/styles';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { LoaderContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BIDashboardSettings,
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>;
  showMetrics?: boolean;
}

export const FunnelVisualization: React.FC<Props> = ({
  dashboardSettings,
  widget,
  showMetrics = true,
}) => {
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [funnelData, setFunnelData] = useState<BIWidgetDataV2>();

  const fetchWidgetData = debounce(
    (
      previewJson: string,
      abortController: AbortController,
      querySetting: string
    ) => {
      fetchApi<string, BIWidgetDataV2>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/v2/widgets/editor/funnel${querySetting}`,
        queryMethod: 'post',
        queryParams: previewJson,
        setData: (result) => {
          setFunnelData(result);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load metric data ${widget.id}: ${error}`);
        },
        setStatus: setDataStatus,
        signal: abortController.signal,
      });
    },
    1000
  );

  useEffect(() => {
    const abortController = new AbortController();
    // this a special case for the funnel widget,
    // where we need to remove the changes_since filter from the dashboard filters
    const widgetData = { ...widget };
    widgetData.dashboard_filters =
      widgetData.dashboard_filters
        ?.filter((filter) => filter.column.name !== 'shared.__changes_since')
        .filter((elem) => elem.operator !== 'all') ?? [];

    const previewJson = JSON.stringify(widgetData);
    const querySetting = dashboardSettings
      ? `?user_status=${dashboardSettings.userFilter}`
      : '';

    if (
      widget.funnel_stage_column !== undefined &&
      widget.funnel_stages !== undefined &&
      widget.funnel_stages.length > 0
    ) {
      fetchWidgetData(previewJson, abortController, querySetting);
    }

    return () => {
      if (abortController) {
        abortController.abort();
        setFunnelData(undefined);
      }
    };
  }, [
    dashboardSettings?.userFilter,
    widget.funnel_object,
    widget.funnel_stage_column,
    widget.funnel_column,
    widget.time_period,
    JSON.stringify(widget.funnel_conditions),
    JSON.stringify(widget.funnel_metric_list),
    JSON.stringify(widget.funnel_stages),
    JSON.stringify(widget.funnel_top_metrics),
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.widget_filters),
    JSON.stringify(widget.dashboard_filters),
  ]);

  return (
    <>
      {dataStatus === 'loading' && (
        <LoaderContainer>
          <Loader active content="Loading" />
        </LoaderContainer>
      )}

      {dataStatus === 'success' && !!funnelData && (
        <>
          <WidgetTopBar>
            <MetricsBlock>
              {!!funnelData.additional_metrics?.length && showMetrics && (
                <AdditionalMetrics
                  additionalMetrics={funnelData.additional_metrics}
                />
              )}
            </MetricsBlock>
          </WidgetTopBar>

          {widget.chart_type === 'funnel' && (
            <FunnelChart widget={widget} data={funnelData} />
          )}

          {widget.chart_type === 'table' && (
            <FunnelTable widget={widget} funnelData={funnelData} />
          )}
        </>
      )}

      {dataStatus === 'error' && <DataFetchErrorMessage />}
    </>
  );
};
